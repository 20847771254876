<template>
    <el-dialog title="Concedii" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
                <el-col :span='12'>
                    <el-form-item label='Nume' prop="IdAngajat">
                        <el-select class='full-width' v-model='selectedObject.IdAngajat' filterable>
                            <el-option v-for='item in Info.angajati' :key="item.Id" :label='item.Nume' :value='item.Id'></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>                  
                <el-col :span='12'>
                    <el-form-item label='Tip concediu' prop="IdTipConcediu">
                        <el-select class='full-width' v-model='selectedObject.IdTipConcediu' filterable>
                            <el-option v-for='item in Info.tipuri_concedii' :key="item.Id" :label='item.Nume' :value='item.Id'></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>         
                <el-col :span='12'>
                    <el-form-item label='Data inceput' prop="DataStart">
                        <el-date-picker
                        class='full-width'
                        v-model="selectedObject.DataStart"
                        :picker-options="{firstDayOfWeek: 1}"
                        type="date"
                        format="dd.MM.yyyy"
                        value-format='yyyy-MM-dd'
                        placeholder="Alege data inceput" />
                    </el-form-item>
                </el-col> 
                <el-col :span='12'>
                    <el-form-item label='Data sfarsit' prop="DataStop">
                        <el-date-picker
                        class='full-width'
                        v-model="selectedObject.DataStop"
                        :picker-options="{firstDayOfWeek: 1}"
                        type="date"
                        format="dd.MM.yyyy"
                        value-format='yyyy-MM-dd'
                        placeholder="Alege data sfarsit" />
                    </el-form-item>
                </el-col>  
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import moment from 'moment';

    export default {
        name: "Concedii_dialog",
        extends: BasePage,
        components: {
        },
        
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObjectTemplate: {Nume: '', DataStart:'', DataStart:'', IdTipConcediu:'' },    
                selectedObject:{},              
                Info:{ angajati:[], tipuri_concedii:[]},
                rules: {
                    IdAngajat:           [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdTipConcediu:       [ { required: true, message: 'Campul este obligatoriu' } ], 
                    DataStart:           [ { required: true, message: 'Campul este obligatoriu' } ],
                    DataStop:            [ { required: true, message: 'Campul este obligatoriu' } ]
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.selectedObject = JSON.parse(JSON.stringify(this.selectedObjectTemplate));
                this.showPopup      = true;
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode           = "edit";
                    var result          = await this.post("concedii/get_info_item_dialog", { id: id } );
                    this.selectedObject = result.Item;
                }
            },
            async get_info(){
                var response                = await this.post("concedii/get_info_for_dialog" );
                this.Info.angajati          = response.Angajati
                this.Info.tipuri_concedii   = response.Tipuri_concedii
            },
            save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("concedii/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>